






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PaymentInvoice extends Vue {
  @Prop({ required: true })
  private paymentInvoice!: string
}
