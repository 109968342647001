








































































import { Component, Ref } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import PaymentInvoice from '@/components/_uikit/PaymentInvoice.vue'
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ProfileModule from '@/store/modules/profile'
import {
  CourseRecurringPaymentResource,
  CourseType,
} from '@/store/types'
// utils
import { countDayInMonth, formatDate } from '@/utils/functions'

@Component({
  components: {
    ButtonArrow,
    Confirmation,
    PaymentInvoice,
    Tag,
  },
})
export default class CourseItem extends NotifyMixin {
  @Ref() confirm!: Confirmation

  private get countDay() {
    if (this.course)
      return countDayInMonth(this.lastMonth)
    return 0
  }

  private get courseID() {
    return +this.$route.params.courseID
  }

  private get lastMonth() {
    if (this.course)
      return this.course.boughtGroup.months[this.course.boughtGroup.months.length - 1].name
    return ''
  }

  private get isSpecial() {
    return this.course ? this.course.boughtGroup.course.type.value === CourseType.SPECIAL : false
  }

  private get month() {
    return formatDate(this.lastMonth, 'MMM yyyy')
  }

  private course: CourseRecurringPaymentResource | null = null

  private recurrent = true

  private mounted() {
    this.fetchCourseGraphicPayments()
  }

  private fetchCourseGraphicPayments() {
    ProfileModule.fetchCourseGraphicPayments(this.courseID)
      .then(response => {
        this.course = response
        this.recurrent = response.boughtGroup.hasRecurringPayment
      })
      .catch(() => {
        this.notifyError('График платежей не найден')
        this.$router.back()
      })
  }

  private handleClickRecurrent() {
    if (!this.recurrent) {
      this.confirm.open(
        'Отключение автопродления',
        `Вы действительно хотите отключить автоматическую оплату курса <strong class="secondary--text">${this.course?.boughtGroup.course.title}</strong>? После отключения автоплатеж можно будет подключить снова при оплате курса.`,
        {
          buttonConfirmText: 'Отключить',
        },
      )
        .then(() => {
          ProfileModule.cancelRecurrentPayment(this.courseID)
            .then(() => {
              if (this.course)
                this.course.scheduledPayments = this.course.scheduledPayments.filter(payment => payment.orderId)
            })
            .catch((err) => {
              this.recurrent = true
              this.notifyError(err)
            })
        })
        .catch(() => this.recurrent = true)
    }
  }
}
